/*  global store */
const App = {
    template: `<appComponent></appComponent>`,
    beforeRouteLeave(to, from, next) {
        window.document.title = this.$store.state.setting.MenuTitle;
        if(this.$store.state.cat_selected)
            this.$store.dispatch('updateCategory',null);
        next();
    }
};

const AppFilter = {template: `<genericFilterPage></genericFilterPage>`};

const LoginView = {
    template: `<loginPage></loginPage>`, 
    beforeRouteEnter: (to, from, next) => {
        next();
    }
};

const RecoveryPasswordView = {template:`<recoveryPasswordPage></recoveryPasswordPage>`};

const RegisterView = {template:`<registerPage></registerPage>`};

const ProfileView = {template: `<profilePage></profilePage>`};

const Home = {template: `<homePage></homePage>`};

const P404 = {template: `<p404Component></p404Component>`};

const Badges = {template: `<badgesPage></badgesPage>`};

const ItemView = {
    template: `<itemPage></itemPage>`,
    beforeRouteEnter: (to, from, next) => {
        const {params} = to;
        if (params.id) {
            let item = store.getters.getItemByCode(params.id);
            if (item) {
                to.meta.gmt = item.Name;
            }
        }
        next();
    },
    beforeRouteUpdate: (to, from, next) => {
        const {params} = to;
        if (params.id) {
            let item = store.getters.getItemByCode(params.id);
            if (item) {
                to.meta.gmt = item.Name;
            }
        }
        next();
    }
};

//const OrderDetail = {template: `<orderDetailComponent></orderDetailComponent>`};

const OrderView = {template: `<orderViewComponent></orderViewComponent>`};

const FavouritiesOrderView = {template: `<favouriteOrderViewComponent></favouriteOrderViewComponent>`};

const FavoritiesView = {template: `<favoritiesItemPage></favoritiesItemPage>`};

const GiftView = {template: `<customerGiftsComponent></customerGiftsComponent>`};

const SuggestionView = {template: `<suggestionComponent></suggestionComponent>`};

const GenericPersonView = {template: `<profileComponent></profileComponent>`};

const InvoiceList = {templateL: `<invoiceList></invoiceList>`};

const ReportPage = {template: `<genericReportPage></genericReportPage>`};

const IndicatorPage = {template:`<genericIndicatorPage></genericIndicatorPage>`};

const PaymentPage = {template:`<orderPaymentPage></orderPaymentPage>`};

const PaymentsResultPage = {template:`<paymentsResultPage></paymentsResultPage>`};

const FAQs = {template:`<FAQsPage></FAQsPage>`};

const AboutUs = {template:`<AboutUsPage></AboutUsPage>`};

const ComplaintPage = {template:`<complaintPage></complaintPage>`};

const PromotionPage = {template:`<promotionPage></promotionPage>`};

const urlSanitize = (value)=>{
    let newValue = value.replace(/ /g,"-");
    newValue = newValue.replace(/\//,"");
    newValue = newValue.replace(/[\])}[{(]/g,"");
    return newValue;
};
// eslint-disable-next-line no-unused-vars
let globalRouter = [
    {name: 'HomeRoot', path: '/', redirect: '/home'},
    {name: 'HomeLink', path: '/home', component: Home, meta: {gmt: "Home"}, 
        beforeEnter: (to, from, next) => {
            store.state.setting ? window.document.title = store.state.setting.MenuTitle : null;
            next();
        }
    },
    {name: 'ComplaintView', path: '/complaint', component: ComplaintPage, meta: {gmt: "Complaint"}},
    {name: 'ComplaintThanksView', path: '/complaint/:thanks', component: ComplaintPage},
    {name: 'GenericFilters', path: '/filter/:aliasCode', component: AppFilter, meta: {gmt: "ComplaintThanks"}},
    {name: 'LoginLink', path: '/login', component: LoginView, meta: {gmt: "Login"}},
    {name: 'RecoveryPasswordView', path: '/recovery_password', component: RecoveryPasswordView, meta: {gmt: "Recovery Password"}},
    {name: 'SignUpView', path: '/signup/:referent?', component: RegisterView, meta: {gmt: "Sign Up"}},
    {name: 'SignUpThank', path: '/thank', component: RegisterView, meta: {gmt: "Sign Up Thank"}},
    {name: 'ProfileLinkAlias', path: '/profile/:activeView', component: ProfileView, meta: {gmt: "Profile"},
        beforeEnter: (to, from, next) => {
            store.dispatch('setOrderView',"all");   // ...
            next();
        }
    },
    {name: 'ProfileLink', path: '/profile', component: ProfileView, meta: {gmt: "Profile"},
        beforeEnter: (to, from, next) => {
            store.dispatch('setOrderView',"all");   // ...
            next();
        }
    },
    {name: 'ProfileOrderLink', path: '/order/:SerNr', component: ProfileView, meta: {gmt: "Order View"},
        beforeEnter: (to, from, next) => {
            store.dispatch('setOrderView',"all");
            next();
        }
    },
    {name: 'ItemView', path: '/view/:id', component: ItemView, meta: {gmt: "Item Detail"},
        redirect: (to, from, next) => {
            const {params} = to;
            if (params.id) {
                let item = store.getters.getItemByCode(params.id);
                if (item) {
                    to.meta.gmt = item.Name;
                    window.document.title = item.Name;
                    return `/detail/${encodeURIComponent(urlSanitize(item.Classification_format)) || "generic"}/${encodeURIComponent(urlSanitize(item.Code))}/${encodeURIComponent(urlSanitize(item.Name))}`;
                }
            }
            return '/NotFound';
        }
    },
    {name: 'ItemList', path: '/items', query: {search: null, mode: 'list', category: null}, component: App, redirect: (to, from, next) => {
        const {query} = to;
        if (query.category) {
            let category = query.category;
            return `/category/${encodeURIComponent(urlSanitize(category))}`;
        }
        return `/products`;
    }},
    {name: 'ItemListSEO', path: '/products', query: {search: null, mode: 'list', category: null}, component: App},
    {name: 'ItemViewSEO', path: '/detail/:category/:id/:name', component: ItemView},
    {name: 'CatSearch', path: '/search/:categoryString', component: App},
    {name: 'CategorySEO', path: '/category/:category', component: App,
        beforeEnter: (to, from, next) => {
            store.state.setting ? window.document.title = store.state.setting.MenuTitle : null;
            next();
        }},
    {name: 'PromotionsSEO', path: '/promotion/:code', component: PromotionPage},
    {name: 'Gifts', path: '/gifts', component: GiftView},
    {name: 'Suggestions', path: '/suggestions', component: SuggestionView},
    {name: 'Favorities', path: '/favorities', component: FavoritiesView},
    {name: 'Badges', path: '/badges', component: Badges,
        beforeEnter: (to, from, next) => {
            if(store.state.session)
                next();
            else {
                if(store.state.appState === "ready") {
                    store.state.redirectAfterLogin = "/badges";
                    switch(store.state.setting.RouteBeforeCar) {
                        case 1:
                            next('/signup');
                            break;
                        default:
                            next('/login');
                            break;
                    }   
                } else {
                    next();
                }
            }
        }
    },
    {name: 'Shopping', path: '/latestshopping/:filter', component: OrderView},
    {name: 'FavouritiesOrder', path: '/favouritesOrders/:filter', component: FavouritiesOrderView},
    {name: 'OrderDetail', path: '/orderdetail/:SerNr',redirect: '/order/:SerNr'},
    {name: 'Invoices', path: '/invoices', component: InvoiceList},
    {name: 'PersonInfo', path: '/genericuser', component: GenericPersonView},
    {name: 'ReportView', path: '/report/:reportName', component: ReportPage},
    {name: 'IndicatorView',path: '/indicators', component: IndicatorPage},
    {name: 'PaymentView', path: '/payment/:orderid', component: PaymentPage},
    {name: 'PaymentsView', path: '/payments/:info', component: PaymentsResultPage},
    {name: 'FAQSsView', path: '/faqs', component: FAQs},
    {name: 'AboutUsView', path: '/about', component: AboutUs},
    {name: 'ErroPage', path: '/error/*', component: P404, alias: "/NotFound/*"},
    {name: 'P404', path: '/*', component: P404, alias: "*"}, // all paths are defined without the hash.
];
